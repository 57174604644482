<template>
  <v-card
    style="height: 100%; width: 100%; max-width: 380px"
    :class="$vuetify.breakpoint.mdAndUp ? 'min-height: 920px' : 'mx-auto'"
    class="pt-12 d-flex flex-column"
    tile flat
    color="transparent"
  >
    <div style="flex: 1">
      <v-hover v-slot:default="{ hover }">
        <div
          @click="$router.push({path: '/dossiers-selectivitat?cart=true'})"
          class="d-flex align-center"
          style="height: 28px; max-width: fit-content; cursor: pointer"
        >
          <v-icon size="18"
                  :color="hover ? 'black' : '#ACACAC'"
          >{{ mdiArrowLeft() }}
          </v-icon>
          <v-slide-x-reverse-transition leave-absolute>
                  <span
                    v-if="hover"
                    class="font-weight-medium ml-2"
                    style="font-size: 14px; min-width: 100px"
                  >
                    Salir
                  </span>
          </v-slide-x-reverse-transition>

          <v-slide-x-transition
            hide-on-leave
          >
            <v-img
              v-if="!hover"
              src="../../../../assets/logo.png"
              height="28" width="28"
              contain
              style="max-width: 28px"
              class="ml-2"
            ></v-img>
          </v-slide-x-transition>
        </div>
      </v-hover>


      <loading-left
        v-if="loading"
      ></loading-left>

      <cart-items v-else-if="items && items.length > 0"
                      :items="items"
                      :allBooks="books"
                      :selected-shipment="selectedShipping"
                      :shippings="shippings"
                      :is-checkout="true"
                      class=" mx-auto"
                      :class="$vuetify.breakpoint.smAndDown ? 'pa-4 grey lighten-4 rounded-lg my-6' : 'mt-6'"
      ></cart-items>

    </div>


    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      class="d-flex align-center mt-12 pt-12 mb-6">
      <span style="color: rgba(26,26,26,.5); font-size: 13px !important;">Powered by</span>

      <poweredby-stripe></poweredby-stripe>

      <contact></contact>

      <legales></legales>
    </div>


  </v-card>
</template>

<script>
import {mdiArrowLeft} from "/src/assets/mdi.json";
import LoadingLeft from "./components/LoadingLeft.vue";
import PoweredbyStripe from "./more-information/PoweredbyStripe.vue";
import Contact from "./more-information/Contact.vue";
import Legales from "./more-information/Legales.vue";
import CartItems from "../../cart/components/CartItems.vue";

export default {
  name: "LeftSide",
  components: {Legales, Contact, PoweredbyStripe, LoadingLeft, CartItems},
  methods: {
    mdiArrowLeft() {
      return mdiArrowLeft
    }
  },
  props: {
    loading: Boolean,
    items: Array,
    books: Array,
    shippings: Array,
    selectedShipping: Number,
  }
}
</script>

<style scoped>

</style>
